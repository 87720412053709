import { ComponentRef } from '@wix/platform-editor-sdk';

import { connectSearchBox } from '../searchAppController';
import { initSearchBoxSuggestions } from '../searchBox';
import { EditorAppContext } from '../types';
import { getConnectedSearchBoxes } from '../getConnectedSearchBoxes';

export async function reconnectSearchBoxes(
  appContext: EditorAppContext,
  allSearchBoxes: ComponentRef[],
) {
  const connectedSearchBoxes = await getConnectedSearchBoxes(appContext);
  const unconnectedSearchBoxes = allSearchBoxes.filter(
    (componentRef) =>
      !connectedSearchBoxes.some((x) => x.id === componentRef.id),
  );

  if (unconnectedSearchBoxes.length > 0) {
    appContext.sentry.captureMessage(
      `There are ${allSearchBoxes.length} search boxes in total, and ${unconnectedSearchBoxes.length} unconnected search boxes`,
    );

    await Promise.all(
      unconnectedSearchBoxes.map((componentRef) =>
        connectSearchBox(appContext, componentRef),
      ),
    );

    connectedSearchBoxes.push(...unconnectedSearchBoxes);
  }

  await Promise.all(
    connectedSearchBoxes.map((connectedSearchBoxRef) =>
      initSearchBoxSuggestions(appContext, connectedSearchBoxRef),
    ),
  );
}
